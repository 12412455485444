<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aboutsection">
          <a href="../../prayers" class="cta-link">Back to prayers</a>
          <h2 class="pt-4" style="color: #0b416c">Mysteries Of The Rosary</h2>
          <hr />
          <div class="section-title mt-4">
            <h5 class="mt-4" id="joyfulmysteries">Joyful Mysteries</h5>
            <ul>
              <li>
                <h6>Annunciation</h6>
                <p>
                  The Angel Gabriel appears to Mary, announcing she is to be the
                  Mother of God.
                </p>
              </li>
              <li>
                <h6>Visitation</h6>
                <p>
                  Elizabeth greets Mary: “Blessed art Thou amoung women and
                  blessed is the fruit of thy womb”
                </p>
              </li>
              <li>
                <h6>Nativity</h6>
                <p>
                  The Virgin Mary gives birth to the Redeemer of the World. “And
                  this will be a sign for you: you will find an infant wrapped
                  in swaddling clothes and lying in a manger.”
                </p>
              </li>
              <li>
                <h6>Presentation at the Temple</h6>
                <p>The Blessed Mother presents the Child Jesus in the Temple</p>
              </li>
              <li>
                <h6>Finding in the Temple</h6>
                <p>The Blessed Mother finds Jesus in the Temple.</p>
              </li>
            </ul>
            <h5 class="mt-4" id="sorrowfulmysteries">Sorrowful Mysteries</h5>
            <ul>
              <li>
                <h6>Agony of Jesus in the Garden</h6>
                <p>
                  At Gethsemane, Jesus prays as He contemplates the sins of the
                  world.
                </p>
              </li>
              <li>
                <h6>Scourging of Jesus at Pillar</h6>
                <p>
                  Jesus is cruelly scourged until His mortified body could bear
                  no more
                </p>
              </li>
              <li>
                <h6>Crowning with Thorns</h6>
                <p>A crown of thorns is placed on the head of Jesus.</p>
              </li>
              <li>
                <h6>Carrying the Cross</h6>
                <p>
                  Jesus carries the heavy cross upon His shoulders to Calvary.
                </p>
              </li>
              <li>
                <h6>Crucifixion</h6>
                <p>
                  Jesus is nailed to the cross and dies after hours of agony
                </p>
              </li>
            </ul>
            <h5 class="mt-4" id="gloriousmysteries">Glorious Mysteries</h5>
            <ul>
              <li>
                <h6>Resurrection of Jesus</h6>
                <p>
                  Jesus rises glorious and immortal, three days after His death.
                </p>
              </li>
              <li>
                <h6>Ascension of Jesus</h6>
                <p>
                  Jesus ascends into Heaven forty days after His Resurrection
                </p>
              </li>
              <li>
                <h6>Descent of Holy Spirit</h6>
                <p>The Holy Spirit descends upon Mary and the Apostles.</p>
              </li>
              <li>
                <h6>Assumption of the Virgin Mary</h6>
                <p>Mary is united with her Divine Son in Heaven.</p>
              </li>
              <li>
                <h6>Coronation of Blessed Virgin Mary</h6>
                <p>Mary is gloriously crowned Queen of Heaven and earth.</p>
              </li>
            </ul>
            <h5 class="mt-4" id="lightmysteries">Light Mysteries</h5>
            <ul>
              <li>
                <h6>Baptism of Jesus</h6>
                <p>
                  The heavens open wide and the voice of the Father declares
                  Jesus the beloved Son
                </p>
              </li>
              <li>
                <h6>Wedding of Cana</h6>
                <p>
                  The first of the signs, Christ changes water into wine thanks
                  to the intervention of Mary
                </p>
              </li>
              <li>
                <h6>The proclamation of the Kingdom of God</h6>
                <p>Jesus proclaims the coming of the Kingdom of God.</p>
              </li>
              <li>
                <h6>The Transfiguration</h6>
                <p>
                  The glory of the Godhead shines forth from the face of Christ
                  as the Father.
                </p>
              </li>
              <li>
                <h6>The institution of the Eucharist</h6>
                <p>
                  Christ offers his body and blood as food under the signs of
                  bread and wine.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.cta-link {
  border-radius: 30px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #0880e8;
  color: #0880e8;
}
h6 {
  font-family: "Eb Garamond", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #0022bb;
}
.cta-link:hover {
  color: #fff;
  background-color: #0880e8;
}
.socialfooter {
  padding: 0px 0px 10px 0px !important;
}
.social-links a {
  margin-right: 20px;
}
.social-links {
  display: flex;
}
.saintheading {
  margin: 0 auto;
  padding: 50px;
}
select.selectpicker {
  padding: 5px 10px;
  font-size: 16px;
}
.about {
  padding: 30px 30px 30px 30px;
  background: #f9f9f9;
  position: relative;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
